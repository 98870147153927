import React from "react";
const About = () => {
   return (
      <div id="about2">
         <div className="container">
            <div id="title">О фонде</div>
            <div className="about2">
               <p>
                  Наша организация одна из первых неправительственных
                  организаций в городе Астана начала оказывать социальные услуги
                  детям и молодежи с инвалидностью. Для эффективной работы с
                  данной категорией в2010 году были созданы Центры дневного
                  пребывания «Балам-ай». С 2011 года организация оказывает
                  специальные социальные услуги в рамках государственного
                  социального заказа при поддержке ГУ «Управление занятости и
                  социальной защиты населения г. Астана» Миссией нашей
                  организации является формирование навыков самопомощи,
                  всестороннее развитие для реализации равных возможностей в
                  обществе детей с особыми нуждами.
               </p>
            </div>
         </div>
      </div>
   );
};

export default About;
