import React from "react";const FAQ = () => {
   return (
      <div id="faq">
         <div className="faq-container">
            <div className="faq">
               <h3 id="title">Часто задаваемые вопросы</h3>
               <h3>
                  - С какого возраста и с какими диагнозами принимают детей в
                  центр?
               </h3>
               <h5>
                  - Принимаем детей с 1,5 года до 18 лет - это младшая группа. С
                  18 лет и старше - это старшая группа.
               </h5>
               <h3>- Платно или бесплатно?</h3>
               <h5>- Бесплатно</h5>
               <h3>
                  - Какой пакет документов необходимо собрать и куда отнести?
               </h3>
               <h5>
                  - Мед. карта оригинал, Справка об инвалидности, Выписка ИПР
                  (обязательно должна быть запись дневное пребывание или
                  полустационар), Св. о рождении ребенка, Уд. личности мамы.
               </h5>
               <h3>- Делятся ли центры по приему детей по диагнозу?</h3>
               <h5>
                  - Центры не делятся по диагнозам, они делятся по месту
                  жительства
               </h5>
               <h3>- Сколько детей в группе? Режим работы?</h3>
               <h5>- В группе 20 детей. Режим работы с 8.00 до 18.00</h5>
            </div>
         </div>
      </div>
   );
};

export default FAQ;
